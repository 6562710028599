import React from 'react';
import {Link} from "react-router-dom";

export default class Apartments extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            current: 0
        }
    }

    static propTypes = {
        roomsArray: React.PropTypes.array.isRequired,
        localization: React.PropTypes.object.isRequired
    };

    tabClick(key){
        this.setState({
            current: key
        })
    }

    render() {
        return <section data-scroll="apartments" className="slider">
            {this.props.roomsArray.map((item, key) => {

                const style = {
                    backgroundImage: item.photos[0] !== undefined ?`url(${item.photos[0].image})` : '',
                    opacity: key === this.state.current ? 1 : 0, // if keys are the same - the item is visible
                    display: key === this.state.current ? 'block' : 'none'
                };


                return <div key={key} style={style} className="slider__item">
                    <Link to={window.location.pathname +'/' + item['type_slug']} className="slider__link">
                        <div className="slider__link-item">
                            <div className="slider__link-text">
                                {this.props.localization['more_details']}
                                <div className="slider__link-divider">

                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            })}
            <div className="slider__nav">
                {this.props.roomsArray.map((item, key) => {
                    return <div onClick={()=>{this.tabClick(key)}} key={key} className={this.state.current === key ? "slider__nav-item--active" : "slider__nav-item"}>
                        <div className="slider__nav-text">
                            {item.type}
                        </div>
                    </div>
                })}
            </div>
        </section>
    }
}
