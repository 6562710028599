export const ROOMS_DATA_LOADED = 'LOAD_ROOMS_DATA';

export const LOAD_CLEANING_PRICE = 'LOAD_CLEANING_PRICE';

export const AVAILABLE_LOCALIZATIONS_LOADED = 'AVAILABLE_LOCALIZATIONS_LOADED';
export const LOAD_INDEX_LOCALIZATION_DATA = 'LOAD_INDEX_LOCALIZATION_DATA';
export const LOAD_ROOM_LOCALIZATION_DATA = 'LOAD_ROOM_LOCALIZATION_DATA';


// reservation

export const COMPLETE_GUESTS_AND_RESERVATION = 'COMPLETE_GUESTS_AND_RESERVATION';
export const COMPLETE_DATES = 'COMPLETE_DATES';
export const COMPLETE_TOTAL = 'COMPLETE_TOTAL';

// set booked rooms data
export const LOAD_BOOKED_ROOMS = 'LOAD_BOOKED_ROOMS';
