import * as types from '../actionTypes';

/**
 *
 * @param data.adults
 * @param data.children
 * @param data.roomsNumber
 * @return {{type, data: *}}
 */
export const completeGuestsAndReservation = (data) => {
    return {
        type: types.COMPLETE_GUESTS_AND_RESERVATION,
        data
    }
};

/**
 *
 * @param data.from
 * @param data.to
 * @return {{type, data: *}}
 */
export const completeDates = (data) => {
    return {
        type: types.COMPLETE_DATES,
        data
    }
};


/**
 *
 * @param data {Array}
 * @return {{type, data: *}}
 */
export const loadBookedDates = (data) => {
    return {
        type: types.LOAD_BOOKED_ROOMS,
        data
    }
};