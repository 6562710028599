import React from 'react';

export default class Banner extends React.Component {

    static propTypes = {
        bannerUrl: React.PropTypes.string.isRequired
    };

    render() {
        const bannerStyle = {
            backgroundImage: this.props.bannerUrl !== undefined ? `url(${this.props.bannerUrl})` : ''
        };
        return <section className="banner">
            <div style={bannerStyle} className="banner__banner-img">
                <div className="banner__table-wrapper">
                    <div className="banner__table-cell-wrapper">
                        <div className="banner__text-block">
                            <div className="banner__text-bordered">
                                <div className="banner__title-wrapper">
                                    <div className="banner__title">
                                        High Heaven Residence
                                    </div>
                                    <div className="banner__divider"> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}