import React from 'react';

export default class About extends React.Component{

    static propTypes = {
      localization: React.PropTypes.object.isRequired
    };

    render(){
        console.log(this.props.localization);
        return <section data-scroll="about" className="about">
            <div className="about__title">
                {this.props.localization['about']}
            </div>
            <div className="about__divider">

            </div>
            <div className="about__cols-wrapper">
                <div className="about__col">
                   {this.props.localization['about_left']}
                </div>
                <div className="about__col">
                    {this.props.localization['about_right']}
                </div>
            </div>
        </section>
    }
}