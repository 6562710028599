import * as types from '../actionTypes';

let initialState = {
    name: "",
    surname: "",
    email: "",
    phone: "",
    comment: ""

};

export default function (state=initialState, action) {
    return initialState;
}
