import * as types from '../actionTypes';

let initialState = {
    availableLocalizations: [],
    roomLocalization: {},
    indexLocalization: {},
};

export default function (state=initialState, action) {

    let newState = Object.assign({}, state);

    switch (action.type){
        case types.AVAILABLE_LOCALIZATIONS_LOADED: {
            newState.availableLocalizations = action.data;
            return newState;
        }
        case types.LOAD_INDEX_LOCALIZATION_DATA: {
            newState.indexLocalization = action.data;
            return newState;
        }
        case types.LOAD_ROOM_LOCALIZATION_DATA: {
            newState.roomLocalization = action.data;
            return newState;
        }
    }
    return newState;
}
