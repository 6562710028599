import React from 'react';
import {connect} from "react-redux";
import mapStateToProps from "../../utils/mapStateToProps";
import api from "../../api";


@connect(mapStateToProps)
export default class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            info: {}
        };
    }

    changeEmail(e) {
        this.setState({
            email: e.target.value
        })
    }

    componentDidMount() {
        api.loadMapData()
            .then(data => {
                this.setState({info:data})
            });
    }

    subscribe() {
        api.subscribe(this.state.email)
            .then(() => {
                alert('success!');
            })
            .catch(err => {
                alert('something went wrong! Try again');
            })

    }

    render() {
        const localization = this.props.store.localizationReducer.indexLocalization;
        return <footer className="footer">
            <div className="container">
                <div className="footer__socials-container">
                    <div className="footer__socials-line">
                        <a target="_blank" href="https://www.facebook.com/highheavenresidence/ " className="footer__socials-circle">
                            <img height="20" src="/static/assets/img/facebook.png" alt=""/>
                        </a>
                        <a target="_blank" href="https://www.booking.com/hotel/cz/high-heaven.en-gb.html" className="footer__socials-circle">
                            <img height="20" src="/static/assets/img/booking.png" alt=""/>
                        </a>
                    </div>
                    <a href="/" className="footer__socials-logo">
                        <img src="/static/assets/img/logo-white.png" alt=""/>
                    </a>
                    <div className="footer__socials-right-line">
                        <div className="footer__socials-line">
                            <div className="footer__socials-payment">
                                <img height="20" src="/static/assets/img/visa.png" alt=""/>
                            </div>
                            <div className="footer__socials-payment">
                                <img height="20" src="/static/assets/img/paypal.png" alt=""/>
                            </div>
                            <div className="footer__socials-payment">
                                <img height="20" src="/static/assets/img/mastercard.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__divider">

                </div>
                <div className="footer__info-col">
                    <div className="footer__info-title">
                        {localization['footer_mail']}
                    </div>
                    <div className="footer__info-text">
                        <a href={`mailto:${this.state.info.email}`}>{this.state.info.email}</a>
                    </div>
                </div>
                <div className="footer__info-col">
                    <div className="footer__info-title">
                        {localization['footer_call']}
                    </div>
                    <div className="footer__info-text">
                        <a href={`tel:${this.state.info.phone}`}>{this.state.info.phone}</a>
                    </div>
                </div>
                <div className="footer__info-col">
                    <div className="footer__info-title">
                        {localization['footer_find_us']}
                    </div>
                    <address className="footer__info-text">
                        {this.state.info.address}
                    </address>
                </div>
                <div className="footer__accomodation">
                    {localization['footer_accommodation']}
                </div>
                <div className="footer__divider">

                </div>
                <div className="footer__subscribe-form">
                    <input className="footer__subscribe-input" type="email"
                           value={this.state.email}
                           onChange={(e) => {
                               this.changeEmail(e)
                           }}
                           placeholder={localization['footer_enter_your_name']}
                    />
                    <button onClick={(e)=>{this.subscribe()}} className="footer__subscribe-btn">
                        {localization['footer_subscribe']}
                    </button>
                </div>
            </div>
        </footer>
    }
}