import * as types from '../actionTypes';

let initialState = {
    guestAndRoomsData: {
        adults: 2,
        children: 1,
        numberOfRooms: 1,

        name: '',
        surname: '',
        phone: '',
        email: '',
        country: '',
        city: ''
    },
    datesOfStay: {
        checkIn: '',
        checkOut: ''
    },

    bookedDates: []

};

export default function (state=initialState, action) {

    let newState = Object.assign({}, state);

    switch (action.type){
        case(types.COMPLETE_GUESTS_AND_RESERVATION): {
            newState.adults = action.data.adults;
            newState.children = action.data.children;
            newState.numberOfRooms = action.data.roomsNumber;
            return newState;
        }
        case (types.LOAD_BOOKED_ROOMS): {
            newState.bookedDates = action.data.bookedDates;
            return newState;
        }
        case (types.COMPLETE_DATES): {
            newState.datesOfStay.checkIn = action.data.from;
            newState.datesOfStay.checkOut = action.data.to;
            return newState;
        }
    }


    return initialState;
}
