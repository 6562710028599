import React from 'react';
import api from '../api';

export default class Contacts extends React.Component {

    static propTypes = {
        localization: React.PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            surname: '',
            phone: '',
            email: '',
            comment: '',
            showPopup: false
        }
    }

    changeName(e) {
        this.setState({
            name: e.target.value
        })
    }

    changeSurname(e) {
        this.setState({
            surname: e.target.value
        })
    }

    changePhone(e) {
        this.setState({
            phone: e.target.value
        })
    }

    changeEmail(e) {
        this.setState({
            email: e.target.value
        })
    }

    changeComment(e) {
        this.setState({
            comment: e.target.value
        })
    }

    submitForm() {
        api.sendForm({
            name: this.state.name,
            surname: this.state.surname,
            phone: this.state.phone,
            email: this.state.email,
            comment: this.state.comment
        }).then(() => {
            this.setState({
                showPopup: true,
                name: '',
                surname: '',
                phone: '',
                email: '',
                comment: ''
            })
        }).catch(err => {
            alert(err);
        })
    }

    hidePopup() {
        this.setState({
            showPopup: false
        })
    }

    render() {
        const localization = this.props.localization;
        return <section className="contacts">
            <div className="contacts__title">
                {localization['contact_us']}
            </div>
            <div className="contacts__divider">

            </div>
            <div className="container">
                <div className="contacts__input-wrapper">
                    <div className="contacts__input-name">
                        {localization['contact_us_name']}
                    </div>
                    <input value={this.state.name}
                           onChange={(e) => {
                               this.changeName(e)
                           }}
                           type="text" className="contacts__input js-form-name"
                    />
                </div>
                <div className="contacts__input-wrapper">
                    <div className="contacts__input-name">
                        {localization['contact_us_surname']}
                    </div>
                    <input value={this.state.surname}
                           onChange={(e) => {
                               this.changeSurname(e)
                           }}
                           type="text" className="contacts__input js-form-surname"/>
                </div>
                <div className="contacts__input-wrapper">
                    <div className="contacts__input-name">
                        {localization['contact_us_phone']}
                    </div>
                    <input value={this.state.phone}
                           onChange={(e) => {
                               this.changePhone(e)
                           }}
                           type="text" className="contacts__input js-form-email"/>
                </div>
                <div className="contacts__input-wrapper">
                    <div className="contacts__input-name">
                        {localization['contact_us_email']}
                    </div>
                    <input value={this.state.email}
                           onChange={(e) => {
                               this.changeEmail(e)
                           }}
                           type="text" className="contacts__input js-form-phone"/>
                </div>
                <div className="contacts__input-name margin-top-50">
                    {localization['contact_us_comment']}
                </div>
                <textarea value={this.state.comment}
                          onChange={(e) => {
                              this.changeComment(e)
                          }}
                          name="" id="" cols="30" rows="10" className="contacts__textarea js-form-contacts"/>
                <div className="contacts__button-wrapper">
                    <button onClick={() => {
                        this.submitForm()
                    }} className="default-button js-form-button">
                        {localization['contact_us_send']}
                    </button>
                    <div className="contacts__hidden-err-text">
                        Please, enter all the fields
                    </div>
                </div>
            </div>
            { this.state.showPopup ? <div onClick={() => {
                this.hidePopup()
            }} className="modal-form">
                <div className="modal-background"></div>
                <div className="modal-item">
                    <div className="modal__inner">
                        <div className="modal__title">
                            thank you!
                        </div>
                        <div className="modal__subtitle">
                            We have received your message. We'll contact you ASAP
                        </div>
                    </div>
                </div>
            </div> : null}
        </section>
    }
}
