import React from 'react';

import {Provider, connect} from 'react-redux';
import mapStateToProps from '../utils/mapStateToProps';
import RoomBanner from "../components/RoomBanner";
import Reservation from "../components/reservation/Reservation";

@connect(mapStateToProps)
export default class Room extends React.Component{
    render() {

        // finds necessary room in array of rooms in reducer
        let roomData = null;

        for( let room of this.props.store.roomsReducer.rooms){
            if(room['type_slug'] === this.props.match.params.room){
                roomData = room;
            }
        }

        if(roomData === null){
            window.location.pathname = '/';
        }

        return <div>
            <RoomBanner
                localization={this.props.store.localizationReducer.roomLocalization}
                roomData={roomData}
            />

            <Reservation
                room={roomData}
            />
        </div>
    }
}