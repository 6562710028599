import * as types from '../actionTypes';

/**
 *
 * @param data {Array}
 * @return {{type, data: *}}
 */
export const availableLocalizationLoaded = (data) => {
    return {
        type: types.AVAILABLE_LOCALIZATIONS_LOADED,
        data
    }
};


export const loadIndexLocalization = (data) => {
    return {
        type: types.LOAD_INDEX_LOCALIZATION_DATA,
        data
    }
};

export const loadRoomLocalization = (data) => {
    return {
        type: types.LOAD_ROOM_LOCALIZATION_DATA,
        data
    }
};