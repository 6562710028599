import React from 'react';

export default class RoomBanner extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            sliderIndex: 0
        };
    }

    componentDidMount(){
        const photosLength = this.props.roomData.photos.length;
        setInterval(()=>{
            this.setState({
                sliderIndex: photosLength !== this.state.sliderIndex + 1 ? ++this.state.sliderIndex: 0
            })
        }, 3000)
    }

    static propTypes = {
        localization: React.PropTypes.object.isRequired,
        roomData: React.PropTypes.object.isRequired
    };

    render() {
        const localization = this.props.localization;
        const room = this.props.roomData;
        return <div>
            <section className="room-description">
                <div className="room-description__title">
                    {room.type}
                </div>
                <div className="room-description__divider">

                </div>
                <div className="room-description__text">
                    {room.description}
                </div>
            </section>
            <section className="slider">
                {room.photos.map((item, key) => {

                    const style = {
                        backgroundImage: `url(${item.image})`,
                        height: '800px',
                        opacity: key === this.state.sliderIndex ? 1 : 0 // if key equals to state's current - shown
                    };

                    return <div
                        key={key}
                        style={style}
                        className="slider__item"
                    >

                    </div>
                })}
            </section>
        </div>
    }
}