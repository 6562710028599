import React from 'react';

export default class RoomDetails extends React.Component {

    static propTypes = {
        room: React.PropTypes.object.isRequired,
        localization: React.PropTypes.object.isRequired
    };

    render() {

        const localization = this.props.localization;

        return <div>
            <div className="container">
                <div className="reservation__title">
                    {localization['details']}
                </div>
            </div>
            <div className="container centered">
                <div className="reservation__detail-col">
                    <div className="reservation__detail-title">
                        {localization['details_place']}
                    </div>
                    <div className="reservation__detail-text">
                        {this.props.room.places}
                    </div>
                </div>
                <div className="reservation__detail-col">
                    <div className="reservation__detail-title">
                        {localization['details_square']}
                    </div>
                    <div className="reservation__detail-text">
                        {this.props.room.square}
                    </div>
                </div>
            </div>
        </div>
    }
}