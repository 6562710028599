import React from 'react';
import {Link} from "react-router-dom";
import Banner from "../components/Banner";
import About from "../components/About";
import Apartments from "../components/Appartments";
import Contacts from "../components/Contacts";
import Map from "../components/Map";

import {Provider, connect} from 'react-redux';
import {createStore, combineReducers} from 'redux';
import mapStateToProps from '../utils/mapStateToProps';

import { browserHistory } from 'react-router';

import $ from 'jquery';
import api from "../api";

@connect(mapStateToProps)
export default class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: {
                banner: ''
            }
        }
    }

    componentDidMount() {
        api.loadMapData()
            .then(data => {
                console.log(data);
                this.setState({
                    settings: data
                })
            });
    }

    static propTypes = {};

    render() {
        // TODO! стращный костыль! убрать из рендера
        switch (this.props.location.query) {
            case 'about': {
                $('html, body').animate({
                    scrollTop: 1150
                }, 1000);
                break;
            }
            case 'apartments': {
                $('html, body').animate({
                    scrollTop: 1750
                }, 1000);
                break;
            }
            case 'contacts': {
                $('html, body').animate({
                    scrollTop: 3900
                }, 1000);
                break;
            }
            default: {
                break;
            }
        }
        return <div>
            <Banner bannerUrl={this.state.settings.banner}/>
            <About
                localization={this.props.store.localizationReducer.indexLocalization}
            />
            <Apartments
                localization={this.props.store.localizationReducer.indexLocalization}
                roomsArray={this.props.store.roomsReducer.rooms}
            />
            <Contacts
                localization={this.props.store.localizationReducer.indexLocalization}
            />
            <Map
                localization={this.props.store.localizationReducer.indexLocalization}
            />
        </div>
    }
}
