import React from 'react';
import $ from 'jquery';
import api from '../api';

export default class Map extends React.Component {

    componentDidMount() {
        const head = document.getElementsByTagName('head')[0];
        const mapScript = document.createElement('script');
        initMap().init();
        mapScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAbJ8NIfdqgc0g_F61gBFePa6W3nAncdFE&callback=initMap';
        head.appendChild(mapScript);
    }

    render() {
        return <section className="map">
            <div className="map__wrapper">

            </div>
        </section>
    }
}

function initMap(){
/**
     *
     * @param data.address
     * @param data.phone
     * @param data.longtitude
     * @param data.lattitude
     */
    const run = (data) => {
        console.log('loading');
        // Create a map object and specify the DOM element for display.
        const googleMapsSelector = document.getElementsByClassName('map__wrapper')[0],
            styles = [
                {
                    "featureType": "all",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "gamma": 0.5
                        }
                    ]
                }
            ];
        const infowWindow = new google.maps.InfoWindow({
            content: `
                    <div class="map__infowindow">
                        <div class="map__infowindow-title">
                            HIGH HEAVEN RESIDENCE
                        </div>
                        <strong>Address:</strong> ${data.address} <br/>
                        <strong>Telephone:</strong> ${data.phone}
                    </div>
                `
        });
        const map = new google.maps.Map(googleMapsSelector, {
            center: {lat: data.lattitude, lng: data.longtitude},
            scrollwheel: false,
            navigationControl: false,
            mapTypeControl: false,
            scaleControl: false,
            draggable: true,
            zoom: 17,
        });
        const marker = new google.maps.Marker({
            position: {lat: 50.088316, lng: 14.416898},
            map: map,
            title: 'High Heaven Residence'
        });
        map.setOptions({styles: styles}); // Customizes the map
        marker.setVisible(false); // Hide default red marker
        infowWindow.open(map, marker); // Shows infoWindow
        $('.gm-style-iw').css({ // Customizes info windows, disables UI and so on
            'background': 'rgba(0,0,0,0.9)'
        });
        google.maps.event.addListenerOnce(map, 'idle', function () {
            console.log($('.map__infowindow').parent());
            $('.map__infowindow').parent().css({
                'overflow': 'hidden'
            });
            $('.gm-style-iw').prev().children().css({
                'background': 'rgba(0,0,0, 0.5)'
            });
            console.log($('.gm-style-iw').prev().find('div:eq(2)').length)
            // finds default triangles
            $('.gm-style-iw').prev().find('div[style*="background-color: rgb(255, 255, 255)"]').css({
                'background': 'rgba(0,0,0,0.5)',
                'margin-top': '3px',
                'box-shadow': 'none'
            });
            // removes background from triangles wrapper
            $('.gm-style-iw').prev().find('div[style*="top: -1px;"]').css({
                'background': 'transparent'
            });
            // removes background from .gm-style-iw parent
            $('.gm-style-iw').prev().find('div:eq(0)').css({
                'background': 'transparent'
            });
            $('.gm-style-iw').next().css({'display': 'none'})
        });
    };


    return {
        init: () => {
            window.initMap = function () {
                console.log('initMap');
                api.loadMapData()
                    .then(data => {
                        run(data);
                    });
            }
        }
    }
}
