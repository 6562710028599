import React from 'react';

export default class Amenities extends React.Component {

    static propTypes = {
        amenities: React.PropTypes.array.isRequired,
        localization: React.PropTypes.object.isRequired
    };

    render() {
        console.log(this.props.amenities);
        return <div className="container">
            <div className="reservation__title">
                {this.props.localization['amenities']}
            </div>
            <div className="reservation__amenities-container">
                {this.props.amenities.map((item, key) => {
                    return <div key={key} className="reservation__amenity">
                        {item.name}
                    </div>
                })}
            </div>
        </div>
    }
}