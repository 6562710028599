import request from 'superagent';

export default {
    /**
     * Loads map data from url `/api/settings`. Returns promise
     * @return {Promise}
     */
    loadMapData: () => {
        return new Promise((resolve, reject) => {
            request.get('/api/settings').end((err, res) => {
                resolve(JSON.parse(res.text));
            })
        })
    },

    loadRooms: (lang) => {
        return new Promise((resolve, reject) => {
            request.get(`/api/${lang}/rooms`).end((err,res)=>{
                if(err === null){
                    resolve(JSON.parse(res.text))
                } else {
                    reject(err);
                }
            })
        })
    },

    /**
     * Loads array of available localizations: [{name: 'cz'}, ...]
     * @return {Promise}
     */
    getAvailableLocales: () => {
        return new Promise((resolve, reject) => {
            request.get('/api/localizations').end((err, res) => {
                console.log(res.text);
                resolve(JSON.parse(res.text))
            })
        })
    },

    /**
     * Simple request to get cleaning price. Cleaning price is used once for the total price
     * @return {Promise}
     */
    getCleaningPrice: () => {
        return new Promise((resolve, reject) => {
            request.get('/api/cleaning-price').end((err, res) => {
                if( err !== null){
                    reject(err);
                } else {
                    resolve(JSON.parse(res.text));
                }
            })
        })
    },


    /**
     *
     * @param lang {String}
     * @param page {String}
     * @return {Promise}
     */
    loadLocalizationData: (lang, page) => {
        return new Promise((resolve, reject) => {
            request.get(`/api/localization/${lang}/${page}`).end((err, res) => {
                if(err !== null){
                    reject(err);
                }
                else{
                    resolve(JSON.parse(res.text))
                }
            })
        })
    },

    /**
     *
     * @param data.name
     * @param data.surname
     * @param data.email
     * @param data.phone
     * @param data.comment
     * @return {Promise}
     */
    sendForm: (data) => {
        return new Promise((resolve, reject) => {
            request.post('/api/contact-us')
                .send(data)
                .end((err, res) => {
                    if (res.statusCode === 204) {
                        resolve();
                    } else {
                        reject();
                    }
                })
        })
    },

    /**
     *
     * @param email {String}
     */
    subscribe: (email) => {
        return new Promise((resolve, reject) => {
            request.post(`api/subscriptions/subscribe`)
                .send({
                    "email": email
                })
                .end((err, res) => {
                    if(err === null){
                        res.statusCode === 204 ? resolve() : reject();
                    }
                })
        })
    }
}
