import React from 'react';
import ReactDOM from 'react-dom';
import {render} from 'react-dom';
import api from './api';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect
} from 'react-router-dom';

import {Provider, connect} from 'react-redux';
import {createStore, combineReducers} from 'redux';

import Index from "./containers/Index";
import Room from "./containers/Room";
import Nav from "./components/globals/Nav";
import Footer from "./components/globals/Footer";

import formReducer from "./reducers/formReducer";
import localizationReducer from "./reducers/localizationReducer";
import reservationReducer from "./reducers/reservationReducer";
import roomsReducer from "./reducers/roomsReducer";
import cleaningReducer from './reducers/cleaningReducer';


import mapStateToProps from "./utils/mapStateToProps";

import {roomDataLoaded} from './actions/roomsActions';
import {availableLocalizationLoaded, loadIndexLocalization, loadRoomLocalization} from './actions/localizationActions';
import {cleaningPriceLoad} from "./actions/cleaningActions";

let reducers = combineReducers({formReducer, localizationReducer, reservationReducer, roomsReducer, cleaningReducer});
const store = createStore(reducers);

@connect(mapStateToProps)
class App extends React.Component {

    constructor(props) {
        super(props);
        console.log(window.location.href);
        this.state = {
            dataReady: false,
            localizationNotFound: false
        }
    }

    redirectToHome(e) {
        window.location.pathname = '/en';
    }

    componentDidMount() {
        let locale = '',
            lang = window.location.pathname.split('/')[1];
        api.getAvailableLocales().then(data => {
            this.checkCurrentLocalization(data, lang); // throws exception if localization is not found
            this.props.dispatch(availableLocalizationLoaded(data));
        }).then(() => {
            api.loadLocalizationData(lang, 'index').then(indexLocalization => {
                this.props.dispatch(loadIndexLocalization(indexLocalization));
            });
            api.loadLocalizationData(lang, 'room').then(pageLocalization => {
                this.props.dispatch(loadRoomLocalization(pageLocalization));
            });
        }).catch(err => {
            console.log(err);
            this.setState({
                localizationNotFound: true,
                dataReady: true
            })
        });

        api.getCleaningPrice().then(data => {
            this.props.dispatch(cleaningPriceLoad(data.price))
        }).catch(err => {
            console.log(err);
            this.setState({
                localizationNotFound: true,
                dataReady: true
            })
        });

        api.loadRooms(lang).then(data => {
            console.log(data);
            this.props.dispatch(roomDataLoaded(data));
            this.setState({
                dataReady: true
            })
        }).catch((err) => {
            console.log(err);
            this.setState({
                localizationNotFound: true,
                dataReady: true
            })
        })
    }

    checkCurrentLocalization(data, currentLang) {
        for (let item of data) {
            if (item.name === currentLang) {
                return true;
            }
        }
        throw new Error('no available localization found!')
    }


    render() {
        console.log('rendered');
        return this.state.dataReady === true ?
            this.state.localizationNotFound === false ?
                <Router>
                    <div>
                        <Nav/>
                        <Route exact path="/:lang/"
                               component={Index}/>
                        <Route path="/:lang/:room"
                               component={Room}/>
                        <Footer />
                    </div>
                </Router> :
                <div onClick={(e) => {
                    this.redirectToHome(e)
                }} className="modal-form">
                    <div className="modal-background"></div>
                    <div className="modal-item">
                        <div className="modal__inner">
                            <div className="modal__title">
                                Page not found!
                            </div>
                            <div className="modal__subtitle">
                                We are sorry, but it seems as there is no page you are looking for :( <br/>
                                <span
                                    className="underlined">Click anywhere to let us redirect you to the home page</span>
                            </div>
                        </div>
                    </div>
                </div> : <div>Loading...</div>
    }
}

ReactDOM.render((
    <Provider store={store}>
        <App />
    </Provider>
), document.getElementById('root'));