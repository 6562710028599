import React from 'react';
import Amenities from "./Amenities";
import mapStateToProps from "../../utils/mapStateToProps";
import {connect} from "react-redux";
import {completeDates, completeGuestsAndReservation, loadBookedDates} from "../../actions/reservationActions";
import api from "../../api";
import moment from 'moment';
import RoomDetails from "./RoomDetails";

@connect(mapStateToProps)
export default class Reservation extends React.Component {

    static propTypes = {
        room: React.PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            authToken: '',
            rid: ''
        };
        this.completeGuestsAndRooms = this.completeGuestsAndRooms.bind(this);
        this.completeDates = this.completeDates.bind(this);
        this.completeReservation = this.completeReservation.bind(this);
    }


    /**
     * Loads auth token and initializes braintree;
     *
     */
    componentDidMount(){

    }


    /**
     *
     * @param data.adults
     * @param data.children
     */
    completeGuestsAndRooms(data) {
        if (data.adults === '' || data.children === '') {
            alert('fill all the necessary fields');
        } else {
            this.setState({
                current: ++this.state.current
            });
            this.props.dispatch(completeGuestsAndReservation({
                adults: data.adults,
                children: data.children,
            }));
            this.getBookedDates(this.props.room['type_slug'], (data) => {
                this.props.dispatch(loadBookedDates(data));
            });
        }
    }

    completeDates(from, to) {
        if (from === '' || to === '') {
            alert('specify both checkIn and checkOut dates');
        } else {
            this.setState({
                current: ++this.state.current
            });
            this.props.dispatch(completeDates({
                from: from,
                to: to
            }))
        }
    }

    /**
     *   @param data {{name:string, surname:string, phone:string, email:string, country:string, city:string, comment:string}}
     *
     */
    completeReservation(data, payment) {
        const totalPrice = moment(this.props.store.reservationReducer.datesOfStay.checkOut, 'DD.MM.YYYY')
                .diff(moment(this.props.store.reservationReducer.datesOfStay.checkIn, 'DD.MM.YYYY'), 'days')
            * parseFloat(this.props.room['price_per_night'])
            + parseFloat(this.props.store.cleaningReducer.price);

        console.log(totalPrice);

        let request = {
            dates: {
                from: this.props.store.reservationReducer.datesOfStay.checkIn,
                to: this.props.store.reservationReducer.datesOfStay.checkOut
            },
            adults: this.props.store.reservationReducer.guestAndRoomsData.adults,
            children: this.props.store.reservationReducer.guestAndRoomsData.children,
            rooms: this.props.store.reservationReducer.guestAndRoomsData.numberOfRooms,
            room_price: this.props.room['price_per_night'],
            cleaning_price: this.props.store.cleaningReducer.price,
            total: totalPrice,
            guest: {
                name: data.name,
                surname: data.surname,
                phone: data.phone,
                email: data.email,
                country: data.country,
                city: data.city,
                comment: data.comment
            }
        };
        return new Promise((resolve, reject) => {
          api.bookRoom(request, this.props.room['type_slug']).then(data => {
              this.setState({
                rid: data.rid
              }, ()=>{resolve()})
          }).catch(err => {
              alert(err);
          })
        })
      }


    /**
     *
     * @param slug
     * @param dispatchCallback {function}
     */
    getBookedDates(slug, dispatchCallback) {
        api.getBookedDates(slug).then(data => {
            dispatchCallback(data);
        }).catch(err => {
            alert(err);
            console.log(err);
        })
    }


    render() {

        const localization = this.props.store.localizationReducer.roomLocalization;

        return <div>
            <section className="reservation">

                <RoomDetails
                    room={this.props.room}
                    localization={localization}
                />

                <div className="reservation__mt80">

                </div>

                <Amenities
                    amenities={this.props.room.amenities}
                    localization={localization}
                />

                <div className="reservation__mt118">
                    <a target="_blank" href="http://hotels.cloudbeds.com/reservation/dc3NzR " className="reservation__complete-btn">
                        Book Now
                    </a>
                </div>

            </section>
        </div>
    }
}
