import * as types from '../actionTypes';

let initialState = {
    price: ''

};

export default function (state=initialState, action) {
    let newState = Object.assign({}, state);
    switch (action.type){
        case(types.LOAD_CLEANING_PRICE): {
            newState.price = action.data;
            console.log(newState);
            return newState;
        }
        default: {
            return state;
        }
    }
}
