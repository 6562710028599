import * as types from '../actionTypes';

/**
 *
 * @param price {String}
 * @return {{type, data: *}}
 */
export const cleaningPriceLoad = (price) => {
    return {
        type: types.LOAD_CLEANING_PRICE,
        data: price
    }
};

