import * as types from '../actionTypes';

let initialState = {
    rooms: []
};

export default function (state=initialState, action) {
    switch (action.type){
        case types.ROOMS_DATA_LOADED: {
            state.rooms = action.data;
            return state;
        }
    }
    return initialState;
}
