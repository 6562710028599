import React from 'react';
import {connect} from "react-redux";
import mapStateToProps from "../../utils/mapStateToProps";
import {Link} from "react-router-dom";

@connect(mapStateToProps)
export default class Nav extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            localizationExpanded: false
        };
    }

    static propTypes = {};

    toggleLocalizations() {
        this.setState({
            localizationExpanded: !this.state.localizationExpanded
        })
    }

    render() {
        let lang = window.location.pathname.split('/')[1],
            localization = this.props.store.localizationReducer.indexLocalization;
        const localizations = this.props.store.localizationReducer.availableLocalizations;
        return <nav className="nav">
            <div className="nav__container">
                <div className="nav__col">
                    <Link className="nav__link" to={{
                        pathname: `/${lang}`,
                        query: 'home'
                    }}>
                        <span className="nav__link__hover-item"> </span>
                        {localization['nav_home']}
                    </Link>
                    <Link className="nav__link" to={{
                        pathname: `/${lang}`,
                        query: 'about'
                    }}>
                        <span className="nav__link__hover-item"> </span>
                        {localization['nav_about']}
                    </Link>
                </div>
                <div className="nav__col centered">
                    <Link to={`/${lang}`} className="nav__logo">
                        <img src="/static/assets/img/logo.png" alt="" width="100"/>
                    </Link>
                </div>

                <div className="nav__col--right">
                    <Link className="nav__link--big" to={{
                        pathname: `/${lang}`,
                        query: 'apartments'
                    }}>
                        <span className="nav__link__hover-item"> </span>
                        {localization['nav_apartments']}
                    </Link>
                    <Link className="nav__link" to={{
                        pathname: `/${lang}`,
                        query: 'contacts'
                    }}>
                        <span className="nav__link__hover-item"> </span>
                        {localization['nav_contacts']}
                    </Link>
                </div>
            </div>
            <div onClick={() => {
                this.toggleLocalizations()
            }} className="nav__localization">
                <a className="nav__localization-language nav__localization-language--current">
                    {lang} &nbsp; &nbsp;
                    <i className="fa fa-angle-down" aria-hidden="true"> </i>
                </a>
                {localizations.map((item, key) => {
                    return item.name !== lang ?
                        <a key={key} href={'/' + item.name}
                           className={this.state.localizationExpanded ? 'nav__localization-language--opened nav__localization-language' : 'nav__localization-language'}
                        >
                            {item.name}
                        </a> : null
                })}

            </div>
        </nav>
    }
}
